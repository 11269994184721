import quickFetch from 'quick-fetch';
import { EarlyRequestKeys } from './earlyRequestKeys';
const connectApp = window.location.pathname.match(/\/oauth\/(\d+)(?:\/embedded)?\/authorize(?:\/|$|\?)/);
const searchParams = new URLSearchParams(window.location.search);
const getDecodedQueryParam = (param, defaultValue) => {
  const queryParam = searchParams.get(param);
  if (!queryParam) return defaultValue;
  const decodedQueryParam = decodeURIComponent(queryParam);
  if (Array.isArray(defaultValue)) {
    return decodedQueryParam.split(' ');
  }
  return decodedQueryParam;
};
const clientId = getDecodedQueryParam('client_id');
const redirectUri = getDecodedQueryParam('redirect_uri');
const scopes = getDecodedQueryParam('scope', []);
const optionalScopes = getDecodedQueryParam('optional_scope', []);
const responseType = getDecodedQueryParam('response_type', 'code');
const makeEarlyRequest = ({
  key,
  url,
  otherOptions
}) => {
  quickFetch.makeEarlyRequest(key, Object.assign({
    url: quickFetch.getApiUrl(url),
    dataType: 'json',
    contentType: 'application/json',
    type: 'GET'
  }, otherOptions));
};
if (clientId) {
  if (connectApp) {
    // On Connect app page
    const hubId = connectApp[1];
    makeEarlyRequest({
      key: EarlyRequestKeys.APP_AUTHORIZATIONS,
      url: `/oauth/v2/application-authorizations`,
      otherOptions: {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          clientId,
          hubId,
          optionalScopes,
          redirectUri,
          responseType,
          scopes
        })
      }
    });
    makeEarlyRequest({
      key: EarlyRequestKeys.SCOPE_GROUPS,
      url: `/oauth/v2/scope-groups/client-hub/${clientId}`
    });
    makeEarlyRequest({
      key: EarlyRequestKeys.INTERNALLY_DEVELOPED_APPS,
      url: `/apps-hublets/public/v3/connection/internally-developed`
    });
  } else {
    // On account selection page
    makeEarlyRequest({
      key: EarlyRequestKeys.APP_INFO,
      url: `/apps-hublets/public/v3/connection/info/client-id/${clientId}`
    });
    makeEarlyRequest({
      key: EarlyRequestKeys.ACCOUNTS_LIST,
      url: `/accounts/v1/accounts/cross-hublet`
    });
  }
}